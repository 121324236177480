<template>
  <base-layout>
    <div class="auditorium-title" style="padding:10px 20px 0 20px" v-if="room"><h4>{{ room.name }}</h4></div>
    <div class="section-header">
      <div class="session-head">
        <strong class="title" v-if="item">{{ item.code }} - {{ item.title }}</strong>
        <strong class="title" v-if="!item">Programme starting soon</strong>
        <!--span ng-if="item.code"> [{{item.code}}]</span--></div>
      <div class="session-head-right" v-if="item">{{ moment(item.start_time).format('dddd HH:mm') }} -
        {{ moment(item.end_time).format('HH:mm') }}
      </div>
    </div>
    <ion-content class=" has-header has-subheader has-bottom-banner" id="wcpt-session-detail">
      <div style="padding:10px;">
        <h3 style="padding:10px;color:red" v-if="isRestrictedAccess">Your registration does not include access to this
          content.</h3>
        <div class="session-content" v-if="!isRestrictedAccess">
          <div class="left-side">
            <div style="font-weight: bold; font-size:1.6rem;line-height:1.6rem;color:red; padding: 10px 20px"
                 v-if="!isOnDemandRoom ">If you hear an echo or you can't see the stream or the Q&A, please refresh this page or press F5.
            </div>
            <div style="font-weight: bold; font-size:1.6rem;color:red; padding: 10px 20px" v-if="isOnDemandRoom ">If you
              can't see the stream, please refresh this page.
            </div>
            <div class="action-buttons" style="max-width:1024px">
            <a
                class="action-btn" style="text-decoration: none"
                v-if="user && item && item.evaluation_link && item.evaluation_link.indexOf('#') == 0"
                :href="'#/app/polls/621c8a9127efaaccf48ad38b/sessions/' + item.id"
            >
              <i class="icon ion-checkmark-circled"></i> Evaluate
            </a>
            <a
                class="action-btn" style="text-decoration: none"
                v-if="user && item && item.evaluation_link && item.evaluation_link.indexOf('#') != 0"
                :href="item.evaluation_link" target="_blank"
            >
              <i class="icon ion-checkmark-circled"></i> Evaluate
            </a>
            </div>
            <div class="stream-container">
              <div class="stream">
                <iframe
                    v-if="streamLink && user"
                    :src="streamLink || item.liveLink"
                    style="position:absolute;top:0;left:0;width:100%;height:100%;"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen class="streamvideo"
                ></iframe>
              </div>
            </div>
            <div class="mobile-activated" style="width:100%"
                 v-if="item && user && !isOnDemandRoom">
              <IonSegment :value="activeTab" class="interactive-segment">
                <IonSegmentButton class="interactive-segment-btn"
                                  value="interaction"
                                  @click="changeTab('interaction')"
                >
                  <IonLabel>Questions</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              <div v-if="activeTab =='interaction' ">
                <user-view
                    :session-id="item.id"
                    v-if="loaded"
                    style="width: 100%;  display:inline-block; vertical-align: top;overflow: hidden;overflow-x:hidden;"
                ></user-view>
                <hr>
              </div>
            </div>

            <div
                class="session-info"
                v-if="!user"
            >Please login to view this session
            </div>
            <div class v-if="item">
              <div class="padding">
                <div class="web-person-divider" style="border:none" v-if="persons && persons.length > 0">
                  <h3>Chairs</h3>
                </div>
                <person-list :persons="persons"></person-list>
                <br/>
                <div class="web-person-divider" style="border:none" v-if="speakers && speakers.length > 0">
                  <h3>Speakers</h3>
                </div>
                <person-list :persons="speakers"></person-list>
              </div>
              <div class="outline" ng-bind-html="item.outline" v-if="item.outline "></div>
              <div class="web-person-divider" style="border:none" v-if="presentations && presentations.length > 0">
                <h3>Presentations</h3>
              </div>
              <presentation-list :presentations="presentations"></presentation-list>
            </div>
            <div v-if="activeSection == 'evaluation'">
              <div
                  v-if="sessionHasStarted || (user && user.emailBusiness == 'dmoore@world.physio')"
              >
                <div
                    v-if="!user"
                    style="margin-left:25px"
                >Please login to take part in the evaluation
                </div>
                <!--poll-loader v-if="user"></poll-loader-->
              </div>
              <div v-if="!sessionHasStarted">
                <div style="margin-left:25px">Evaluation is not active for this session</div>
              </div>
            </div>
          </div>

          <div class="right-side mobile-deactivated" v-if="item && user && !isOnDemandRoom">
            <IonSegment :value="activeTab">
              <IonSegmentButton class="interactive-segment-btn"
                                value="interaction"
                                @click="activeTab = 'interaction'"
              >
                <IonLabel>Questions</IonLabel>
              </IonSegmentButton>
              <!--IonSegmentButton class="interactive-segment-btn"
                  value="forum"
                  @click="activeTab = 'forum'"
              >
                <IonLabel>Comments</IonLabel>
              </IonSegmentButton-->
            </IonSegment>
            <div v-if="activeTab =='interaction'">
              <user-view
                  :session-id="item.id"
                  v-if="item.id"
                  style="width: 100%;  display:inline-block; vertical-align: top;overflow: hidden;overflow-x:hidden;"
              ></user-view>
            </div>
            <div v-if="activeTab =='forum'" style="display:none">
              <forum v-if="loaded"
                     class="forum-list"
                     :ref-id="item.id"
                     style="margin:5px 2px;width: 100%; display:inline-block; vertical-align: top;overflow: scroll;overflow-x:hidden;margin-top:10px; border-radius:15px"
              ></forum>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </base-layout>
</template>

<script>
import {IonContent, IonSegment, IonSegmentButton, IonLabel, alertController} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import PersonList from "@/components/PersonList";
import PresentationList from "@/components/PresentationList";
import UserView from "@/components/interactive/UserView";

const config = require('@/config');
import Forum from "@/components/interactive/Forum";

export default defineComponent({
  name: "SessionDetail",
  data() {
    return {
      item: null,
      loaded: false,
      isLiveSession: false,
      persons: [],
      speakers: [],
      presentations: [],
      activeTab: "interaction",
      activeSection: 'details',
      interval: null,
      sessionHasStarted: false,
      sessionHasEnded: false,
      liveStreamHasStarted: false,
      liveStreamHasEnded: false,
      liveSessionActive: false,
      zoomSessionActive: false,
      actualTime: "",
      activeSessionsByRoom: [],
      roomStreamMapper: {
        'da7ad0c0-3ed1-4500-1302-080000000007': 'https://vimeo.com/event/1558902/embed/396b9df82c',
        'da7ad0c0-3ed1-4500-1302-080000000008': 'https://vimeo.com/event/1558902/embed',
        'da7ad0c0-3ed1-4500-1302-080000000010': 'https://vimeo.com/event/1558902/embed',
        'da7ad0c0-3ed1-4500-1302-080000000009': 'https://vimeo.com/event/1558902/embed',
        'da7ad0c0-3ed1-4500-1302-080000000011': 'https://vimeo.com/event/1558902/embed',
        'da7ad0c0-3ed1-4500-1302-080000000012': 'https://vimeo.com/event/1558902/embed',
      },
      streamLink: null,
      roomId: null,
      room: null,
      isOnDemandRoom: false,
      isRestrictedAccess: false,
      showsAlert: false
    };
  },
  components: {
    IonContent,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    "person-list": PersonList,
    "presentation-list": PresentationList,
    "user-view": UserView,
    forum: Forum

  },
  methods: {
    ...mapActions("sessions", [
      "getSingleSession",
      "getPresentationList",
      "getSpeakersInSession",
      "getSessionPersons",
      "getLocation"
    ]),
    ...mapActions("cmetracking", [
      "uploadTrackingEvents",
      "trackEvent",
    ]),
    ...mapActions("video", ["stopVideos"]),
    async getServerTime() {
      try {
        let time_url =
            "https://mantel-api.mobile.documedias.systems/v2/timestamp";
        return (await this.axios.get(time_url)).data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    getForum(refId) {
      this.$socketInt.emit("getForum", {
        congressId: config.conference,
        refId: refId
      });
    },
    checkTime(session) {
      let dateNow;
      let sessionStart;
      let sessionEnd;
      let liveSessionStart;
      let liveSessionEnd;
      let zoomSessionEnd;
      dateNow = new Date(this.actualTime);
      sessionStart = new Date(session.start_time + "+02:00");
      sessionEnd = new Date(session.end_time + "+02:00");
      this.sessionHasStarted = dateNow > sessionStart;
      this.sessionHasEnded = dateNow > sessionEnd;
      liveSessionStart = moment(sessionStart).subtract(10, "minutes");
      liveSessionEnd = moment(sessionEnd).add(5, "minutes");
      zoomSessionEnd = moment(sessionEnd).add(10, "minutes");
      this.liveStreamHasStarted = dateNow >= liveSessionStart;
      this.liveStreamHasEnded = dateNow >= liveSessionEnd;
      this.liveSessionActive =
          dateNow >= liveSessionStart && dateNow <= liveSessionEnd;
      this.zoomSessionActive =
          dateNow >= liveSessionStart && dateNow <= zoomSessionEnd;
      this.interval = setInterval(() => {
        this.actualTime = moment(this.actualTime).add(5, "seconds");
        dateNow = moment(this.actualTime);
        sessionStart = new Date(session.start_time + "+02:00");
        sessionEnd = new Date(session.end_time + "+02:00");
        this.sessionHasStarted = dateNow > sessionStart;
        this.sessionHasEnded = dateNow > sessionEnd;

        liveSessionStart = moment(sessionStart).subtract(10, "minutes");
        liveSessionEnd = moment(sessionEnd).add(5, "minutes");
        zoomSessionEnd = moment(sessionEnd).add(10, "minutes");
        this.liveStreamHasStarted = dateNow >= liveSessionStart;
        this.liveStreamHasEnded = dateNow >= liveSessionEnd;
        this.liveSessionActive =
            dateNow >= liveSessionStart && dateNow <= liveSessionEnd;
        this.zoomSessionActive =
            dateNow >= liveSessionStart && dateNow <= zoomSessionEnd;
        /*console.log("start", liveSessionStart.format());
        console.log("end", liveSessionEnd.format());
        console.log("now", moment(dateNow).format());
        console.log("Session is live", this.liveSessionActive);*/
      }, 5000);
    },

    checkRestriction() {
      let now = new moment().toISOString();
      if (this.user && this.user.extra && this.user.extra[config.conference]) {
        let extra = this.user.extra[config.conference];
        //let full = extra.tn_category == 'Full';
        let pfd = extra.tn_category_short == 'pfd';
        let virtsat = extra.tn_category_short == 'virtsat';
        let qm = extra.tn_category_short == 'qm';
        let pfdSessions = ['da7ad0c0-3ed1-4500-1302-010000000027', 'da7ad0c0-3ed1-4500-1302-010000000028', 'da7ad0c0-3ed1-4500-1302-010000000029', 'da7ad0c0-3ed1-4500-1302-010000000030', 'da7ad0c0-3ed1-4500-1302-010000000031'];
        let qmSessions = ['da7ad0c0-3ed1-4500-1302-010000000152', 'da7ad0c0-3ed1-4500-1302-010000000156', 'da7ad0c0-3ed1-4500-1302-010000000158', 'da7ad0c0-3ed1-4500-1302-010000000159'];
        this.isRestrictedAccess = false;
        if (this.item != null && this.item.id) {
          if (pfd && pfdSessions.indexOf(this.item.id) == -1) {
            this.isRestrictedAccess = true;
          }
          if (qm && qmSessions.indexOf(this.item.id) == -1) {
            this.isRestrictedAccess = true;
          }
          if (virtsat && this.item.start_time.indexOf('2022-03-19T') == -1) {
            this.isRestrictedAccess = true;
          }
        } else {

          if (pfd || qm) {
            this.isRestrictedAccess = true;
          }
        }
        if (virtsat && now.indexOf('2022-03-19T') == -1) {
          this.isRestrictedAccess = true;
        }

      }
    },
    async showDisclaimer(session) {

      if (session.disclaimer && session.disclaimer.length > 10) {
        if (!this.showsAlert) {
          this.showsAlert = true;
          const alert = await alertController
              .create({
                cssClass: 'my-custom-class',
                header: session.title,
                message: '<strong>' + session.disclaimer + '</strong>',
                buttons: [
                  {
                    text: 'Decline',
                    role: 'cancel',
                    cssClass: 'secondary',
                    id: 'cancel-button',
                    handler: () => {
                      this.showsAlert = false;
                      this.$router.push('/app/dasboard');
                    },
                  },
                  {
                    text: 'Accept',
                    id: 'confirm-button',
                    handler: () => {
                      this.showsAlert = false;

                    },
                  },
                ],
              });
          return alert.present();
        }
      }
    },

    async fetchSession(sessid) {

      this.item = await this.getSingleSession(sessid)
      await this.showDisclaimer(this.item)
      await this.checkRestriction();
      let trackData = {
        user: this.user,
        item: this.item,
        event: 'enter',
        type: 'session',
        itemId: this.item.id,
      }
      this.persons = await this.getSessionPersons(this.item.id);
      this.speakers = await this.getSpeakersInSession(this.item.id);
      this.presentations = await this.getPresentationList(this.item.id);
      //console.log('before track', this.isRestrictedAccess)
      if (!this.isRestrictedAccess) {
        //console.log('TRACK user', trackData)
        this.trackEvent(trackData)
      }


      this.getForum(sessid);
      let links;
      try {
        let external = this.item.external_link.replace(/'/g, '"');
        links = JSON.parse(external);
      } catch (err) {
        links = this.item.external_link;
      }

      this.item.liveLink = links.live;
      this.item.odLink = links.od;
      this.actualTime = await this.getServerTime();
      this.checkTime(this.item);


    },
    joinChannel() {
      this.$socketInt.emit("join", config.conference);
    },
    getActiveSessionsByRoom() {
      this.$socketInt.emit("getActiveSessionsByRoom", {
        congressId: config.conference,
        roomId: this.roomId,
      });
    },

  },
  computed: {
    ...mapGetters('auth', ['user'])
  },

  async created() {

    this.roomId = this.$route.params.id;
    if (this.roomId == 'da7ad0c0-3ed1-4500-1302-080000000014' || this.roomId == 'da7ad0c0-3ed1-4500-1302-080000000013') {
      this.isOnDemandRoom = true;
    }

    this.joinChannel();
    this.getActiveSessionsByRoom();
    this.checkRestriction();
    this.$socketInt.on("getActiveSessionsByRoom", res => {
      //console.log(res);

      if (res && res.room == this.roomId) {
        this.activeSessionsByRoom = [];
        for (let i = 0; i < res.sessions.length; i++) {
          this.activeSessionsByRoom.push(res.sessions[i].sessionId)
        }
        if (this.activeSessionsByRoom.length > 0) {
          let _id = this.activeSessionsByRoom[0];
          this.fetchSession(_id);
        } else {
          if (this.item) {

            //if (!this.isRestrictedAccess) {
              let trackData = {
                user: this.user,
                item: this.item,
                event: 'leave',
                type: 'session',
                itemId: this.item.id,
              }
              this.trackEvent(trackData);

            //}
            this.isRestrictedAccess = false;
          }
          this.checkRestriction()


          this.item = null;
          this.persons = null;
          this.speakers = null;
          this.presentations = null;

          console.log('no active')
        }

      } else {
        console.log('no res')
        if (this.item && !this.isRestrictedAccess) {
          let trackData = {
            user: this.user,
            item: this.item,
            event: 'leave',
            itemId: this.item.id,
            type: 'session'
          }
          this.trackEvent(trackData)
        }
      }
    });
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(to) {
        if (to.name == 'Live Room') {
          this.moment = moment;
          this.roomId = to.params.id;
          this.room = await this.getLocation(to.params.id);
          console.log(this.room)
          this.streamLink = this.room.stream_link ? this.room.stream_link : this.roomStreamMapper[this.roomId];

          if (this.streamLink.indexOf('autoplay') != -1) {
            console.log('AUTOPLAY')
            if (this.streamLink.indexOf('autoplay=0') != -1) {
              //this.streamLink = this.streamLink.replace('autoplay=0', 'autoplay=1')
            }

          }
          this.joinChannel();
          this.getActiveSessionsByRoom();
          this.checkRestriction();
          this.loaded = true;
          this.uploadTrackingEvents();
        } else {
          if (this.item && this.item.id) {
            let trackData = {
              user: this.user,
              item: this.item,
              event: 'leave',
              itemId: this.item.id,
              type: 'session'
            }
            this.trackEvent(trackData)

          }
          this.streamLink = null;
          this.uploadTrackingEvents();
          this.stopVideos();
        }
      }
    }
  },
});
</script>